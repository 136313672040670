import model from './model';
import { ProjectWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectWidgetProps as Props } from './config/types';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/build/es/types';
import {
  commonImagesToWixCode,
  wixImageConvertor,
} from '../../converters/mediaConverter';
import { getCollectionSlugFromUrl, getUrlNextNPrevProject } from '../../utils';
import { ProgalleryImage } from '@wix/ambassador-portfolio-projects-v1-project/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor, isViewer } = flowAPI.environment;

  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectHeaderWidget: $w(ComponentIds.Header),
    gallery: $w(ComponentIds.Gallery),
    navigationWidget: $w(ComponentIds.NavigationWidget),
  });

  const getWixCodePGItems = (projectItems: Item[]) => {
    return commonImagesToWixCode(projectItems);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.project.id) {
      components.projectHeaderWidget.data = {
        title: data.project.title,
        details: data.project?.details,
        description: data.project.description,
        heroImage: wixImageConvertor(
          data.project.coverImage as ProgalleryImage,
        ),
      };
      components.gallery.items = getWixCodePGItems(data.items);
    }
    if (isEditor) {
      components.navigationWidget.data = {
        hideNextButton: false,
        hideBackButton: false,
      };
    } else {
      components.navigationWidget.data = {
        hideNextButton: !data.nextProjectSlug,
        hideBackButton: !data.prevProjectSlug,
      };
    }
  };

  const handleInteractions = () => {
    const { prevProjectSlug, nextProjectSlug } = $widget.props.data;
    const previousProjectUrl = getUrlNextNPrevProject(
      flowAPI,
      getCollectionSlugFromUrl(flowAPI),
      prevProjectSlug,
    );
    const nextProjectUrl = getUrlNextNPrevProject(
      flowAPI,
      getCollectionSlugFromUrl(flowAPI),
      nextProjectSlug,
    );
    if (!isEditor) {
      prevProjectSlug &&
        components.navigationWidget?.onBackClicked(() => {
          flowAPI?.controllerConfig?.wixCodeApi?.location?.to &&
            flowAPI?.controllerConfig?.wixCodeApi?.location?.to(
              previousProjectUrl,
            );
        });
      nextProjectSlug &&
        components.navigationWidget?.onNextClicked(() => {
          flowAPI?.controllerConfig?.wixCodeApi?.location?.to &&
            flowAPI?.controllerConfig?.wixCodeApi?.location?.to(nextProjectUrl);
        });
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    if (components.topContainer.id) {
      initByProps(nextProps);
      handleInteractions();
    }
  });
  return {
    pageReady: async () => {
      components = getComponents();
      if (components.topContainer.id) {
        handleInteractions();
        initByProps($widget.props);
      }
    },
    exports: {},
  };
});
