import model from './model';
import { PortfolioPageWidgetComponentIds as ComponentIds } from './config/constants';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import {
  Collection,
  SortOrder,
} from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { getPageViewMode } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBoxWidget: $w(ComponentIds.MultiStateWidget),
  });

  const getCollections = async () => {
    return flowAPI.httpClient
      .request(
        queryCollections({
          query: {
            filter: { hidden: false },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { collections = [] },
        } = res;
        return collections;
      });
  };

  const initPortfolioPageData = async () => {
    components.multiStateBoxWidget.data = { collections };
  };

  const initPortfolio = async () => {
    collections = await getCollections();
    await initPortfolioPageData();
  };

  return {
    pageReady: async () => {
      components = getComponents();
      await initPortfolio();
      biLogger.report(
        portfolioPageViewed({
          pageName: 'portfolio',
          viewMode: getPageViewMode(flowAPI),
        }),
      );
    },
    exports: {},
  };
});
