export enum CollectionMSBWidgetComponentIds {
  Root = '#collectionMSBWidgetRoot',
  MultiState = '#collectionMultiStateBox',
  GalleryWidget = '#collectionsGalleryWidget',
  ListWidget = '#categoriesListWidget',
  CollectionWidget = '#collectionWidget',
}

export enum CollectionMSBWidgetStates {
  Gallery = 'galleryState',
  // List = 'listState',
  Collection = 'collectionState',
}

export enum CollectionMSBWidgetWidgetEvents {}
// Change = 'change',
