import model from './model';
import {
  CollectionMSBWidgetComponentIds as ComponentIds,
  CollectionMSBWidgetStates as States,
} from './config/constants';
import {
  Collection,
  SortOrder,
} from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { queryProjectsWithCollectionInfo } from '@wix/ambassador-portfolio-projects-v1-project/http';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiState),
    galleryWidget: $w(ComponentIds.GalleryWidget),
    // listWidget: $w(ComponentIds.ListWidget),
    collectionWidget: $w(ComponentIds.CollectionWidget),
  });

  const getCollectionProjects = async (collectionId) => {
    return flowAPI.httpClient
      .request(
        queryProjectsWithCollectionInfo({
          query: {
            filter: {
              collectionId: { $eq: collectionId },
              hidden: { $eq: false },
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return projects;
      });
  };

  const initPortfolioMSB = async (numOfCollections: number) => {
    if (numOfCollections === 1) {
      await components.multiStateBox.changeState(States.Collection);
    } else {
      await components.multiStateBox.changeState(States.Gallery);
    }
  };

  const initPortfolioPageData = async () => {
    if (collections.length === 1) {
      const projectsInCollection = await getCollectionProjects(
        collections[0].id,
      );
      components.collectionWidget.data = {
        collection: collections[0],
        projects: projectsInCollection.map((p) => p.project),
      };
    } else {
      // @ts-expect-error
      components.galleryWidget.data = { collections };
    }
  };

  const initCollectionMSB = async () => {
    collections = $widget.props.data.collections;
    if (!collections) {
      return;
    }
    await initPortfolioMSB(collections.length);
    await initPortfolioPageData();
  };
  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    initCollectionMSB();
  });
  return {
    pageReady: async () => {
      components = getComponents();
      await initCollectionMSB();
    },
    exports: {},
  };
});
