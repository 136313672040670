import _ from 'lodash';
import { Pages } from './editor-app/first-install/const/pagesConsts';
import { FlowEditorSDK, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const COLLECTION_SLUG_PATH_INDEX = 0;
export const PROJECT_SLUG_PATH_INDEX = 1;

export const getBaseUrl = (flowAPI) => {
  return `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}`;
};

export const getPortfolioBaseUrl = (flowAPI) => {
  let portfolioPagePath;
  try {
    const appRouters = flowAPI.controllerConfig.appParams.appRouters;
    const wixPortfolioPagesData = JSON.parse(appRouters[0]?.config)
      ?.portfolioPagesData;
    const portfolioPageData = _.find(
      wixPortfolioPagesData,
      (page) => page.tpaPageId === Pages.PORTFOLIO_PAGE.pageId,
    );
    const portfolioPageTitle = portfolioPageData?.title;
    const sitePages = flowAPI.controllerConfig.wixCodeApi.site.getSiteStructure()
      .pages;
    portfolioPagePath =
      _.find(sitePages, (page) => page.name === portfolioPageTitle)?.url || '/';
  } catch {
    portfolioPagePath = '/portfolio';
  }
  return `${portfolioPagePath}`;
};
export const getPortfolioRouterBaseUrl = (flowAPI) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  return `${getBaseUrl(flowAPI)}/${appRouter.prefix}`;
};

export const getCollectionUrl = (flowAPI, collectionSlug) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  return `/${appRouter.prefix}/${collectionSlug}`;
};

export const getProjectUrl = (flowAPI, collectionSlug, projectSlug) => {
  return `${getCollectionUrl(flowAPI, collectionSlug)}/${projectSlug}`;
};
export const getUrlNextNPrevProject = (
  flowAPI,
  collectionSlug,
  projectSlug,
) => {
  const [appRouter]: any = flowAPI.controllerConfig.appParams.appRouters;
  return `/${appRouter.prefix}/${collectionSlug}/${projectSlug}`;
};

export const getCollectionSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[
    COLLECTION_SLUG_PATH_INDEX
  ];
};

export const getProjectSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[
    PROJECT_SLUG_PATH_INDEX
  ];
};

export const richTextFormatter = (html: string = '') => {
  const tags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'ul', 'ol'];
  const formattedHtml = tags.reduce((prev = '', tag) => {
    const tempString = prev
      ?.toString()
      ?.replaceAll?.(
        `<${tag}>`,
        `<${tag} style="color: inherit;font-family: inherit;font-size: inherit;text-align: inherit;letter-spacing: inherit;line-height: inherit;">`,
      )
      ?.toString();
    return tempString;
  }, html || '');
  return formattedHtml;
};

export const getPageViewMode = (flowAPI: PlatformControllerFlowAPI) => {
  let viewMode = '';
  const { isViewer, isEditor, isPreview } = flowAPI.environment;
  if (isViewer) {
    viewMode = 'liveSite';
  } else if (isEditor) {
    viewMode = 'editor';
  } else if (isPreview) {
    viewMode = 'preview';
  }

  return viewMode;
};

export const refreshAppServices = (editorSDK: FlowEditorSDK) => {
  return async () => {
    await editorSDK.editor.routers.refresh('', { selectedRoute: '' });
    editorSDK.document.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: '',
    });
    editorSDK.document.application.reloadManifest();
  };
};

export const isSeoTagsEnabled = (flowApi: PlatformControllerFlowAPI) => {
  const enableSeoTags = flowApi.experiments.enabled(
    'specs.portfolio.enableSeoTags',
  );
  return enableSeoTags;
};
